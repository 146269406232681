export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)": [3,[2]],
		"/auth/callback": [9],
		"/auth/sign-in": [10],
		"/covid-hub": [11],
		"/(authenticated)/notifications": [4,[2]],
		"/risk-hub": [12],
		"/(authenticated)/risk": [5,[2]],
		"/(authenticated)/risk/alerts": [6,[2]],
		"/(authenticated)/risk/alerts/[riskId]": [7,[2]],
		"/(authenticated)/risk/pax-alerts": [8,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';